import axios from 'axios'

const API = '/usuarios'

const Usuarios = {
    solicitudes(params){
        return axios.get(`${API}/solicitudes`,{params})
    },

    residentes(params){
        return axios.get(`${API}/residentes`,{params})
    },

    aceptarResidente(params){
        return axios.put(`${API}/solicitudes/aceptacion`,params)
    },

    rechazarResidente(params){
        return axios.put(`${API}/solicitudes/rechazo`,params)
    },

    eliminarResidente(idResidente){
        return axios.delete(`${API}/eliminar-residente/${idResidente}`)
    },

    residentesVivienda(params){
        return axios.get(`${API}/residentes-vivienda`,{params})
    },
    
}

export default Usuarios
