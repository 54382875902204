<template>
    <div class="row mb-4">
        <div class="col-12 col-lg-auto">
            <p class="f-20 f-500">{{ titulo }}</p>
        </div>
        <div class="col ml-auto my-auto">
            <slot />
        </div>
        <div class="col-auto">
            <el-input v-model="inputSearch" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: 'Sin titulo'
        }
    },
    data(){
        return{
            inputSearch:''
        }
    },
    watch:{
        inputSearch(val){
            this.$emit('inputSearch',val)
        }
    },
}
</script>

<style lang="css" scoped>
.br-7{
    border-radius: 7px;
}
.br-gr-red{
    background: var(--gr-r-red);
}
.bg-yellow{
    background: #FF9C32;
}
.text-yellow{
    color: #FF9C32;
}
</style>
