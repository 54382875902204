export default [
    {
        path: '/llamados_atencion',
        name: 'llamados.atencion.listado',
        component: () => import('../../pages/llamadosAtencion/listado').then(m => m.default || m),
    },
    {
        path: '/llamados_atencion/ver/:id_llamado',
        name: 'llamados.ver',
        component: () => import('../../pages/llamadosAtencion/verLlamado').then(m => m.default || m),
    },
    {
        path: '/llamados_atencion/nueva',
        name: 'llamados.crear',
        component: () => import('../../pages/llamadosAtencion/crearLlamado').then(m => m.default || m),
    }
]
