import { mapGetters } from 'vuex'
import {Notification} from 'element-ui'
import moment from 'moment'


export default {
    computed: {
        ...mapGetters({
            $usuario: 'auth/user',
        })
    },
    methods: {
        errorCatch(e,referenciaValidador = null){
            const estadosWarning= [400, 422]
            console.error(e);
            if(e.response === undefined) return
            if(e.response && estadosWarning.includes(e.response.data.status)){
                this.notificacion(
                    _.get(e.response,'',''),
                    _.get(e.response,'data.mensaje','Ha ocurrido un error al realizar la consulta'),
                    'warning'
                )
            } else if (_.defaultTo(e.response.status, false) != 401){
                this.notificacion(
                    _.get(e.response,'data.titulo','Error'),
                    _.get(e.response,'data.mensaje','Ha ocurrido un error al realizar la consulta'),
                    'error'
                )
            }
            if(this.existenValidaciones(e) && referenciaValidador){
                this.$refs[referenciaValidador].setErrors(e.response.data.validaciones)
            }
        },
        existenValidaciones: excepcion => !_.isEmpty(excepcion?.response?.data?.validaciones),
        
        notificacion(title,message,type = 'info',isHtml = true){
            Notification({
                title: title,
                message: message,
                type: type,
                dangerouslyUseHTMLString: isHtml
            })
        },
        crearFormData(data){
            const fd = new FormData()
            for(var key in data){
                if(Array.isArray(data[key])){
                    for(var key2 in Object.entries(data[key])){
                        fd.append(`${key}[${key2}]`,data[key][key2])
                    }
                } else {
                    fd.append(key,data[key])
                }
            }
            return fd
        },
        iconUser(tipo){
            switch (tipo){
            case 1:
                return 'f-20 icon-account-outline op-05 bg-azul-claro rounded-circle'  
            case 2:
                return 'f-20 icon-buildings op-05 bg-amarilloclaro rounded-circle'
            case 3:
                return 'f-20 icon-account-outline op-05'    
            case 4:
                return 'f-20 icon-account-outline op-05'    
            default:
                return 'f-20 icon-account-outline op-05'  
            }
        },
        iconExtension(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp'],
                    icon: 'icon-picture color-IcoImagen'
                },
                pdf: {
                    extension: ['pdf'],
                    icon: 'icon-file-pdf-box color-IcoPdf'
                },
                video: {
                    extension: ['avi','mp4','mpg','mpeg','wmv','div'],
                    icon: 'icon-video color-IcoVideo'
                },
                other: {
                    icon: 'icon-doc-inv color-dark'
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return fileTypes.image.icon
            } else if(isPdf){
                return fileTypes.pdf.icon
            } else if(isVideo){
                return fileTypes.video.icon
            } else {
                return fileTypes.other.icon
            }
        },
        fileType(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp']
                },
                pdf: {
                    extension: ['pdf']
                },
                video: {
                    extension: ['mp4']
                    // extension: ['avi','mp4', 'mpg', 'mpeg', 'wmv', 'div'],
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return 'image/*'
            } else if(isPdf){
                return 'application/pdf'
            } else if(isVideo){
                return 'video/*'
            } else {
                return '*'
            }
        },
        delay(callback,ms = 600){
            if(window.retraso) clearInterval(window.retraso)
            window.retraso = setTimeout(callback,ms)
        },
        toPromise(fn){
            return new Promise(async(resolve) => {
                await fn()
                resolve()
            })
        },
        diffMomento(fecha){
            return moment(fecha).fromNow()
        }

    }
}
