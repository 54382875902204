export default [
    {
        path: '/configurar',
        name: 'configurar',
        redirect: {name: 'configurar.tipo.anuncios'},
        component: () => import('../../pages/configurar').then(m => m.default || m),
        children: [
            {
                path: 'tipo_anuncios',
                name: 'configurar.tipo.anuncios',
                component: () => import('../../pages/configurar/tiposAnuncios').then(m => m.default || m),
            },
            {
                path: 'valor_administracion',
                name: 'configurar.valor.admin',
                component: () => import('../../pages/configurar/valorAdmin').then(m => m.default || m),
            },
            {
                path: 'fechas_pago',
                name: 'configurar.fechas.pago',
                component: () => import('../../pages/configurar/fechasPago').then(m => m.default || m),
            },
            {
                path: 'usuario_contador',
                name: 'configurar.usuario.contador',
                component: () => import('../../pages/configurar/usuarioContador').then(m => m.default || m),
            },
            {
                path: 'limites_mensajes',
                name: 'configurar.limites.mensajes',
                component: () => import('../../pages/configurar/limitesMensajes').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/configurar/tipo_anuncios/guardar/:id_tipo?',
        name: 'configurar.guardar.anuncio',
        component: () => import('../../pages/configurar/crearTipoAnuncio').then(m => m.default || m),
    },
    {
        path: '/configurar/tipo_anuncios/guardar/:id_tipo',
        name: 'configurar.editar.anuncio',
        component: () => import('../../pages/configurar/crearTipoAnuncio').then(m => m.default || m),
    },
]
