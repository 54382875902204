<template>
    <button class="btn-action d-middle-bt gap-2" :class="{'filtering': filtering}" @click="$emit('click', $event)">
        <i class="icon-filter f-20" />
        <i class="icon-angle-down f-20" />
    </button>
</template>

<script>
export default {
    props: {
        filtering: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.filtering{
    background: #5A80EA4D;
    border: 1px solid #5A80EA;
}
</style>