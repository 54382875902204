<template>
    <div class="main-layout">
        <div class="position-relative">
            <child />
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'MainLayout',
}
</script>
<style lang="scss" scoped>
main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Rubik';
    font-weight: 300;
    // color: var(--color-5d);
    color: #000;
    height: calc(100vh - 55px);
    width:calc(100vw - 77px);
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;
    top: 0px;
    left: 62px;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    min-height: 95%;
    width: 100%;
    // margin: auto 0;
    //box-shadow: 0 3px 6px 0 #00000029;
    //border-radius: 12px;

}
</style>
