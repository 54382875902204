// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// conjunto.js
export const OBTENER_CONJUNTO = 'OBTENER_CONJUNTO'
export const GUARDAR_CONJUNTO = 'GUARDAR_CONJUNTO'

// reservas.js
export const OBTENER_SEARCH = 'OBTENER_SEARCH'
export const GUARDAR_SEARCH = 'GUARDAR_SEARCH'

export const OBTENER_FILTRO_PAGO = 'OBTENER_FILTRO_PAGO'
export const GUARDAR_FILTRO_PAGO = 'GUARDAR_FILTRO_PAGO'