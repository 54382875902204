export default [
    {
        path: '/viviendas',
        name: 'viviendas',
        redirect: {name: 'viviendas.sistema'},
        component: () => import('../../pages/viviendas').then(m => m.default || m),
        children:[
            {
                path: '/viviendas/viviendas',
                name: 'viviendas.sistema',
                component: () => import('../../pages/viviendas/viviendas').then(m => m.default || m),
            },
            {
                path: '/viviendas/residentes',
                name: 'viviendas.residentes',
                component: () => import('../../pages/viviendas/residentes').then(m => m.default || m),
            },
            {
                path: '/viviendas/solicitudes',
                name: 'viviendas.solicitudes',
                component: () => import('../../pages/viviendas/solicitudes').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/viviendas/ver',
        name: 'viviendas.ver',
        component: () => import('../../pages/viviendas/viviendas/ver').then(m => m.default || m),
        redirect: {name: 'viviendas.ver.pagos'},
        children: [
            {
                path: '/viviendas/:id_vivienda/pagos',
                name: 'viviendas.ver.pagos',
                component: () => import('../../pages/viviendas/viviendas/pagos').then(m => m.default || m),
            },
            {
                path: '/viviendas/:id_vivienda/residentes',
                name: 'viviendas.ver.residentes',
                component: () => import('../../pages/viviendas/viviendas/residentes').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/viviendas/residentes/guardar',
        name: 'viviendas.guardar.residente',
        component: () => import('../../pages/viviendas/viviendas/crearResidente').then(m => m.default || m),
    },
    {
        path: '/viviendas/residentes/guardar/:id_residente',
        name: 'viviendas.guardar.residente.id',
        component: () => import('../../pages/viviendas/viviendas/crearResidente').then(m => m.default || m),
    },
    {
        path: '/viviendas/residentes/:id_residente?',
        name: 'viviendas.ver.residente.personal',
        component: () => import('../../pages/viviendas/verResidente').then(m => m.default || m),
    },
    {
        path: '/viviendas/solicitudes/ver/:id_residente?',
        name: 'viviendas.solicitudes.ver',
        component: () => import('../../pages/viviendas/solicitudes/solicitudUsuario').then(m => m.default || m),
    },

    
]
