export default [
    {
        path: '/propietarios',
        name: 'propietarios',
        component: () => import('../../pages/propietarios').then(m => m.default || m),
    },
	{
        path: '/propietarios/crear',
        name: 'propietarios.crear',
        component: () => import('../../pages/propietarios/crearPropietario.vue').then(m => m.default || m),
    },
	{
        path: '/propietarios/:idPropietario/editar',
        name: 'propietarios.editar',
        component: () => import('../../pages/propietarios/editarPropietario.vue').then(m => m.default || m),
    },
	{
        path: '/propietarios/:id_residente/ver',
        name: 'propietarios.ver',
        component: () => import('../../pages/viviendas/verResidente').then(m => m.default || m),
    },
]
