// state
export const state = {  
  numMesesDebe: 0,
}

// getters
export const getters = {
  getNumMesesDebe: (state) => state.numMesesDebe,
}

// mutations
export const mutations = {
  setNumMesesDebe: (state, nuevoValor) => {
    state.numMesesDebe = nuevoValor
  },    
}

// actions
export const actions = {  
  actualizarNumMesesDebe ({ commit }, nuevoValor ) {
    commit('setNumMesesDebe', nuevoValor)
  }
}