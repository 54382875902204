import Chat from '../../services/chats/chat-admin'
import auth from './auth'
import store from '~/store';
// state
export const state = {  
    chatsGrupos: [],
    grupoActivo:{},
    conversacionGrupo:[],
    mensajesTodos:[],
    pagina:1,
    masMensajes:false,
    usersGrupo:[]
}

// getters
export const getters = {
    chatsGrupos: state => state.chatsGrupos,
    pagina: state => state.pagina,
    masMensajes: state => state.masMensajes,
    grupoActivo: state => state.grupoActivo,
    conversacionGrupo: state => state.conversacionGrupo,
    usersGrupo: state => state.usersGrupo,
}

// mutations
export const mutations = {
    setChatsGrupos: (state,payload) => {
        console.log('aca');
        state.chatsGrupos = _.orderBy(payload,['fecha_ultimo_chat'],['desc']);
    },
    clearConversacion:(state) => {
        state.mensajesTodos = []
        state.conversacionGrupo = []
        state.pagina = 0
        state.masMensajes = false
    },
    setPaginacion:(state,payload)=>{
        if(state.pagina === payload.last_page){
            state.masMensajes = false
        }else{
            state.masMensajes = true
        }
        state.pagina = payload.current_page
    },
    setGrupoActivo: (state,payload) => {
        state.grupoActivo = payload;
    },
    setUsersGrupo: (state,payload) => {
        state.usersGrupo = payload;
    },
    setConversacionGrupo: (state,payload) => {

        state.mensajesTodos = [...payload, ...state.mensajesTodos]
        const groupFechaMensajes = _.groupBy(_.orderBy(state.mensajesTodos,'created_at','DESC'), 'fecha')
       
        const mensajes = Object.keys(groupFechaMensajes).map(key => ({ key, mensajes: groupFechaMensajes[key] }));
        state.conversacionGrupo = _.orderBy(mensajes,'key','DESC');
    },
    initTabGrupos:(state) => {
        state.grupoActivo = {}
        state.conversacionGrupo = []
        state.mensajesTodos = []
        state.pagina = 1
        state.masMensajes = false
        state.usersGrupo = []
    },
    deleteGrupoLista:(state, payload) => {
        const index = state.chatsGrupos.findIndex(g => g.id_grupo === payload)
            
        if(index > -1){
            state.chatsGrupos.splice(index,1)
        }
    },
    setListadoChats:(state, payload) => {
        const index = state.chatsGrupos.findIndex(g => g.id_grupo == payload.id_grupo)
        
        if(index > -1){
            //Removemos el chat actual
            state.chatsGrupos.splice(index,1)
        }
        //Agregamos del primero el chat entrante
        state.chatsGrupos.unshift(payload)
    }
}
export const actions = {  
    async getUsuariosGrupo({ commit }, idGrupo){
        
        const { data } = await Chat.getUsuariosGrupo(idGrupo)
  
        commit('setUsersGrupo', data.usuarios)
    },
    async getDataGrupo({ commit, state }, payload){

        if(state.grupoActivo.id_grupo != payload.id_grupo){
            commit('clearConversacion')
        }else{
            return
        }

        const { data } = await Chat.getDetalleGrupo(payload.id_grupo)
        commit('setGrupoActivo',data.grupo)
        commit('setUsersGrupo',data.usuarios)
  
    },
    async getConversacion({commit,state}, payload){
        state.pagina = state.pagina + 1
        const params = {
            page:state.pagina,
            id_grupo:payload.id_grupo
        }

        const {data} = await Chat.getConversacionAdminGrupo(params)

        commit('setPaginacion',data.data)
        commit('setConversacionGrupo',data.data.data)
    },
    setNewMensaje({commit}, payload){
        const user = store.getters['auth/user'];
        if(user.id === payload.message.created_by){
            payload.message.propio = 1
        }
        commit('setConversacionGrupo',[payload.message])
    },
    updateListado({commit, state}, payload){
        //if(state.residenteActivo.id_user && payload.chat.id_user == state.residenteActivo.id_user) return
        commit('setListadoChats',payload.chat)
    },
    async updateDetalle({ commit }, payload){

        const { data } = await Chat.getDetalleGrupo(payload.id_grupo)
        commit('setGrupoActivo',data.grupo)
        commit('setUsersGrupo',data.usuarios)
  
    },
    
  
}
