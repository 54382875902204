export default [
    {
        path: '/pqr',
        name: 'pqr.main.index',
        redirect: {name: 'pqr.solicitudes'},
        component: () => import('../../pages/pqr').then(m => m.default || m),
        children: [
            {
                path: 'solicitudes',
                name: 'pqr.solicitudes',
                component: () => import('../../pages/pqr/solicitudes').then(m => m.default || m),
            },
            {
                path: 'resueltas',
                name: 'pqr.resueltas',
                component: () => import('../../pages/pqr/resueltas').then(m => m.default || m),
            },
            {
                path: 'resoluciones',
                name: 'pqr.resoluciones',
                component: () => import('../../pages/pqr/resoluciones').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/pqr/solicitudes/:id_pqr?',
        name: 'pqr.ver.solicitud',
        component: () => import('../../pages/pqr/verSolicitud').then(m => m.default || m),
    },
    {
        path: '/pqr/resoluciones/:id_grupo?',
        name: 'pqr.ver.resolucion',
        component: () => import('../../pages/pqr/verResolucion').then(m => m.default || m),
    },
    {
        path: '/pqr/resoluciones/nueva',
        name: 'pqr.crear.resolucion',
        component: () => import('../../pages/pqr/nuevaResolucion').then(m => m.default || m),
    },
]
