<template>
    <div
    ref="modalEliminar"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header f-17 f-500">
                    {{ titulo }}
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-close f-13 cr-pointer" data-dismiss="modal" />
                    </div>
                </div>
                <div class="border-modal-top" />
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                    <div class="container">
                        <div class="row my-3 text-muted">
                            <p class="f-500 f-14 w-100" :class="alignText ? alignText : ''">{{ mensaje }}</p>
                            <p class="f-13 mt-1 w-100 text-center"> <slot /> </p>
                        </div>
                    </div>
                </div>
                <div class="border-modal-bottom" />
                <div class="row mx-0 justify-content-end py-2">
                    <button
                    v-if="btnCancel"
                    type="button"
                    class="btn mx-2 btn-cerrar f-14 br-4 border py-1 f-300"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                    <button
                    v-if="btnConfirm"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-general f-14 text-white br-4 py-1 f-300"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        btns: {
            type: Array,
            default: () => { return [] }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        mensaje: {
            type: String,
            default: ''
        },
        tamano: {
            type: String,
            default: ''
        },
        alignText: {
            type: String,
            default: ''
        },
        btnConfirm: {
            type: Boolean,
            default: true
        },
        btnCancel: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            textosBotones: {
                listo: 'Listo',
                eliminar: 'Eliminar',
                guardar: 'Guardar',
                cancelar: 'Cerrar'
            }
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalEliminar).modal('toggle')
        },
        guardar(){
            this.$emit('guardar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/sass/gbp.scss';
.bg-linea{
    background: linear-gradient(to right, #fff, #D1D1D1, #fff);
    height: 1px;
}
.modal-content{
    border: none;
    border-radius: 8px;
    .border-modal{
        &-top{
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, #E3E3E3, #FFFFFF00);
        }
        &-bottom{
            width: 100%;
            height: 1px;
            background: linear-gradient(to left, #E3E3E3, #FFFFFF00);
        }
    }
    .modal-header{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        padding: 0.6rem 1rem;
        
        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;
            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
}
.btn{
    &-cerrar{
        width: 94px;
        background: var(--color-f5);
    }
    &-general{
        width: 94px;
    }
}
</style>
