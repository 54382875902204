export default [
	{
		path: '/vigilantes',
		name: 'vigilantes',
		component: () => import('../../pages/vigilantes/vigilantes.vue').then(m => m.default || m),
	},
	{
		path: '/vigilantes/crear',
		name: 'vigilantes.crear',
		component: () => import('../../pages/vigilantes/crearVigilante.vue').then(m => m.default || m),
	},
	{
		path: '/vigilantes/:idVigilante/editar',
		name: 'vigilantes.editar',
		component: () => import('../../pages/vigilantes/crearVigilante.vue').then(m => m.default || m),
	},
]
