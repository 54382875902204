import Configurar from './configurar/configurar';
import Pagos from './pagos/pagos';
import Viviendas from './viviendas/viviendas';
import Anuncios from './anuncios/anuncios';
import Encuestas from './encuestas/encuestas';
import ZonasComunes from './zonascomunes/zonascomunes';
import Pqr from './pqr/pqr';
import Propietarios from './propietarios/propietarios';
import Llamados from './llamados/llamados';
import Informacion from './informacion/informacion';
import VentanasEmergentes from './ventanasEmergentes/ventanasEmergentes';
import Chats from './chats/chats';
import Login from './auth/login';
import Vigilantes from './vigilantes/vigilantes'
import permiso from './sinPermiso/permiso';
import correspondencia from './correspondencia/correspondencia';
import Biblioteca from './biblioteca/biblioteca';
import Visitantes from './visitantes/visitantes';
import pagosAdmin from './pagosAdmin/pagosAdmin';
export default [
    // {
    //     path: '/',
    //     redirect: { name: 'viviendas.sistema' },
    //     name: 'home',
    //     component: () => import('../pages/Home.vue').then(m => m.default || m)
    // },
    {
        path: '/dashboard',
        name: 'dashboard.main',
        component: () => import('../pages/dashboard/dashboard.vue').then(m => m.default || m)
    },
    {
        path: '*',
        redirect: { name: "viviendas.sistema" },
    },
    ...Configurar,
    ...Pagos,
    ...Viviendas,
    ...Anuncios,
    ...Encuestas,
    ...ZonasComunes,
    ...Pqr,
    ...Llamados,
    ...Informacion,
    ...Chats,
    ...Login,
    ...VentanasEmergentes,
    ...Vigilantes,
    ...correspondencia,
    ...permiso,
    ...Biblioteca,
	...Propietarios,
    ...Visitantes,
    ...pagosAdmin,

]
