export default [
    {
        path: '/informacion',
        name: 'informacion',
        redirect: {name: 'informacion.preguntas.frecuentes'},
        component: () => import('../../pages/informacion').then(m => m.default || m),
        children: [
            {
                path: 'preguntas_frecuentes',
                name: 'informacion.preguntas.frecuentes',
                component: () => import('../../pages/informacion/preguntasFrecuentes').then(m => m.default || m),
            },
            {
                path: 'terminos_condiciones',
                name: 'informacion.terminos.condiciones',
                component: () => import('../../pages/informacion/terminosCondiciones').then(m => m.default || m),
            },
            {
                path: 'policitas_privacidad',
                name: 'informacion.policitas.privacidad',
                component: () => import('../../pages/informacion/politicaPrivacidad').then(m => m.default || m),
            }
        ]
    },
]
