export default [
    {
        path: '/biblioteca',
        name: 'biblioteca.carpetas',
        component: () => import('../../pages/biblioteca/carpetas.vue').then(m => m.default || m),
    },
    {
        path: '/biblioteca/ver/:idCarpeta',
        name: 'biblioteca.carpetas.archivos',
        component: () => import('../../pages/biblioteca/archivosCarpetas.vue').then(m => m.default || m),
    },
	{
        path: '/biblioteca/carpetas/crear',
        name: 'biblioteca.carpetas.crear',
        component: () => import('../../pages/biblioteca/crearCarpeta.vue').then(m => m.default || m),
    },
	{
        path: '/biblioteca/carpetas/:idCarpeta/editar-privacidad/:privacidad',
        name: 'biblioteca.carpetas.editar.privacidad',
        component: () => import('../../pages/biblioteca/editarPrivacidadCarpeta.vue').then(m => m.default || m),
    },
]
