import * as types from '../mutation-types'
// state
export const state = {  
  search: '',
  filtro_pago: false,
}

// getters
export const getters = {
  search: ({search}) => search,
  filtro_pago: ({filtro_pago}) => filtro_pago
}

// mutations
export const mutations = {
  [types.GUARDAR_SEARCH] (state, { search }) {
    state.search = search
  },
}


// actions
export const actions = {  
  search ({ commit }, search ) {
    console.log(search)
    commit(types.GUARDAR_SEARCH, { search: search })
  }
}

