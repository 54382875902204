export default [
	{
		path: '/ventanas-emergentes',
		name: 'ventanas-emergentes',
		component: () => import('../../pages/ventanasEmergentes').then(m => m.default || m),
	},
	{
		path: '/ventanas-emergentes/crear-ventana/:idEmergente?',
		name: 'ventanas-emergentes.crear-ventana',
		component: () => import('../../pages/ventanasEmergentes/crearVentana').then(m => m.default || m),
	}
]