export default [
    {
        path: '/correspondencia',
        name: 'correspondencia.listado',
        component: () => import('../../pages/correspondencia/listado').then(m => m.default || m),
    },
    {
        path: '/correspondencia/ver/:idCorrespondencia',
        name: 'correspondencia.ver',
        component: () => import('../../pages/correspondencia/ver').then(m => m.default || m),
    },
    {
        path: '/correspondencia/crear',
        name: 'correspondencia.crear',
        component: () => import('../../pages/correspondencia/crear').then(m => m.default || m),
    }
]
