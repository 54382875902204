export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../../pages/auth/login').then(m => m.default || m),
    },
    {
        path: '/login/entrando',
        name: 'login.entrando',
        component: () => import('../../pages/auth/cargando').then(m => m.default || m),
    }
]
