export default [
    {
        path: '/pagos-administracion',
        name: 'pagos.admin',
        redirect: {name: 'pagos.admin.recibo.pagos'},
        component: () => import('../../pages/pagosAdmin/index').then(m => m.default || m),
        children:[
            {
                path: 'recibo-pagos',
                name: 'pagos.admin.recibo.pagos',
                component: () => import('../../pages/pagosAdmin/reciboPagos').then(m => m.default || m),
            },
            {
                path: 'registro-pagos',
                name: 'pagos.admin.registro.pagos',
                component: () => import('../../pages/pagosAdmin/registroPagos').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/pagos-administracion/registro-pagos/:id',
        name: 'pagos.admin.registro.pagos.detail',
        component: () => import('../../pages/pagosAdmin/registroPagosDetail').then(m => m.default || m),
    },
    {
        path: '/pagos-administracion/registro-pagos/:id/info/:id_historial',
        name: 'pagos.admin.registro.pagos.detail.info',
        component: () => import('../../pages/pagosAdmin/registroPagosDetailInfo').then(m => m.default || m),
    },
]
