export default [
    {
        path: '/encuestas',
        name: 'encuestas.main',
        component: () => import('../../pages/encuestas').then(m => m.default || m),
    },
    {
        path: '/encuestas/ver/:id_encuesta',
        name: 'encuestas.ver',
        component: () => import('../../pages/encuestas/ver').then(m => m.default || m),
    },
    {
        path: '/encuestas/nueva',
        name: 'encuestas.crear',
        component: () => import('../../pages/encuestas/nuevaEncuesta').then(m => m.default || m),
    },
    {
        path: '/encuestas/editar/:id_encuesta',
        name: 'encuestas.crear.continuar',
        component: () => import('../../pages/encuestas/nuevaEncuestaContinuar').then(m => m.default || m),
    },
]
