<template>
    <section class="modal-ver-imagen">
        <modal ref="ver_archivo" titulo="" tamano="modal-md" no-cancelar @guardar="toggle">
            <div class="row mx-0 justify-center" oncontextmenu="return false" onkeydown="return false">
                <img :src="ruta" alt="" class="imagen m-auto" />
            </div>
        </modal>
    </section>
</template>

<script>
export default {
    data(){
        return {
            ruta:''
        }
    },
    methods: {
        toggle(ruta){
            this.ruta = ruta
            this.$refs.ver_archivo.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-ver-imagen{
    .imagen{
        max-height: 44vh;
        max-width: 100%;
        object-fit: cover;
    }

}
</style>
