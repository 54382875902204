import axios from 'axios'

const API = '/viviendas'

const Viviendas = {
    indicadoresGenerales(){
        return axios(`${API}/indicadores`)
    },
    mostrar(id_vivienda){
        return axios(`${API}/mostrar/${id_vivienda}`)
    },
    listarViviendas(){
        return axios(`${API}`)
    },
    viviendasHijas(params){
        return axios.get(`${API}/viviendas-hijas`,{params})
    },
    estadisticas(){
        return axios.get(`${API}/estadisticas`)
    },
    agrupaciones(params){
        return axios(`${API}/agrupaciones`,{params})
    },
    hijas(params){
        return axios(`${API}/agrupaciones/hijas`,{params})
    },
    residentes(params){
        return axios(`${API}/residentes`,{params})
    },
    residenteEstadisticas(){
        return axios(`${API}/residentes/estadisticas`)
    },
    getUserPorId(params){
        return axios.get(`${API}/residentes/residente`,{params})
    },
    obtenerResidente(params){
        return axios.get(`${API}/residentes/residente`,{params})
    },
    listarValores(){
        return axios(`${API}/listar-valores`)
    },
    guardarValores(payload){
        return axios.post(`${API}/guardar-valores`,payload)
    },
    listarPagos(params){
        return axios(`${API}/pagos/listar-web`,{params})
    },
    listarPagosVivienda(id_vivienda){
        return axios(`${API}/pagos/listar/${id_vivienda}`)
    },
    listarMesesAPagar(id_vivienda){
        return axios(`${API}/pagos/meses/${id_vivienda}`)
    },
    listarMesesDisponiblesAPagar(id_vivienda){
        return axios(`${API}/pagos/meses/lista/${id_vivienda}`)
    },
    mostrarPago(id_pago){
        return axios(`${API}/pagos/mostrar/${id_pago}`)
    },
    aceptarPago(id_pago){
        return axios.patch(`${API}/pagos/aceptar/${id_pago}`)
    },
    rechazarPago(id_pago){
        return axios.patch(`${API}/pagos/rechazar/${id_pago}`)
    },
    guardarResidente(payload){
        return axios.post(`${API}/residentes`,payload)
    },
    registarPago(payload){
        return axios.post(`${API}/pagos/meses/pagar`,payload)
    },
    ponerPazYSalvo(params){
        return axios.post(`${API}/pagos/pazysalvo`,params)
    },
    noControlarPago(params){
        return axios.put(`${API}/control/pago`,params)
    },
    getViviendas(){
        return axios.get(`${API}/listar`)
    },
    getViviendasHijas(params){
        return axios(`${API}/listar/hijas`,{params})
    },
    editarResidente(payload){
        return axios.put(`${API}/residente/propietario/editar`, payload)
    },
    
}

export default Viviendas
