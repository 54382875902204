<template>
    <section class="space-loading position-absolute w-100 h-100 d-middle-center">
        <lottie-animation
        :path="urlLottie"
        :speed="1"
        :loop="true"
        :width="256"
        :height="256"
        />
    </section>
</template>
<script>
export default {
    data(){
        return {
            urlLottie: 'lottie/loadingMCD.json'
        }
    },
}
</script>
<style lang="scss" scoped>
.space-loading{
	z-index:500;
	background-color: rgb(255 255 255 / 58%);
}
</style>