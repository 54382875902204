<template>
    <el-drawer :visible.sync="drawer" :title="titulo">
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        }
    }
}
</script>
