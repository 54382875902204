<template>
    <div class="card-residente border br-8 pb-2 pt-3 px-3">
        <div class="row" @click="detalleResidente(residente)">
            <div class="col-12 col-sm- col-md-3 col-lg-3">
                <div class="like-img bg1 br-50 d-middle-center f-30 mx-auto">
                    <i class="icon-account-outline op-05" />
                </div>
            </div>
            <div class="col-12 col-sm-9 col-md-9 col-lg-9 text-center text-sm-left pl-0">
                <p class="f-500 f-20 tres-puntos">{{ residente.nombre }} {{ residente.apellido }} </p>
                <p v-if="residente.tipo === 1 && residente.propietario === 1" class="f-14 text-86">Residente - Propietario</p>
                <p v-else class="f-14 text-86">Residente</p>
                <!-- <p class="f-14 text-86">{{ anos(residente.nacimiento) }}</p> -->
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-none d-sm-block" />
            <div class="col-auto pl-lg-0 pl-sm-0">
                <div class="d-flex justify-content-center">
                    <div class="d-flex d-middle" @click="detalleResidente(residente)">
                        <div class="col-auto d-middle-center">
                            <div class="like-img-mini bg-gender br-50 d-middle-center f-17 mr-2">
                                <i class="icon-gender op-05" />
                            </div>
                            <div class="lh-18">
                                <p class="f-12 text-86">Género</p>
                                <p class="f-500 f-14">{{ genero(residente.genero) }}</p>
                            </div>
                        </div>
                        <div class="col-auto d-middle-center">
                            <div class="like-img-mini bg-phone br-50 d-middle-center f-17 mr-2">
                                <i class="icon-cellphone op-05" />
                            </div>
                            <div class="lh-18">
                                <p class="f-12 text-86">Número de celular</p>
                                <p class="f-500 f-14">{{ residente.telefono || 'No registra' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="my-auto ml-4 ml-sm-auto">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                            <div slot="content" class="tooltip-editar-residente">
                                <div class="d-flex mb-2 editar-opcion py-1 px-1 cr-pointer br-4" @click="editarResidente">
                                    <i class="icon-pencil-outline mr-2 f-17" />
                                    <p class="f-15">Editar datos</p>
                                </div>
                                <div class="d-flex my-auto editar-opcion py-1 px-1 cr-pointer br-4" @click="modalEliminarResidente">
                                    <i class="icon-delete-outline my-auto mr-2 f-17" />
                                    <div>
                                        <p class="f-15">Eliminar a {{ residente.nombre }} </p>
                                        <p class="f-13 text-86">Todo registro será borrado del sistema</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar ref="abrirEliminarResidente" titulo="Eliminar residente" adicional="Eliminar" @eliminar="eliminarResidente">
            <p class="text-center f-500 f-15">¿Desea eliminar a {{ residente.nombre }}?</p>
            <p class="text-center f-15">No podrá acceder a Mi Conjunto y todo registro será borrado del sistema</p>
        </modal-eliminar>
    </div>
</template>
<script>
import moment from 'moment'
import Usuarios from '~/services/usuarios'
export default {
    props: {
        residente:{
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return{

        }
    },
    methods:{
        editarResidente(){
            this.$router.push({name: 'viviendas.guardar.residente.id', params: {id_residente: this.residente.id}})
        },
        async eliminarResidente(){
            try {
                const { data } = await Usuarios.eliminarResidente(this.residente.id)
                this.$refs.abrirEliminarResidente.toggle()
                this.notificacion('','Residente eliminado correctamente','success')
                this.$emit('actualizar')
            } catch (error){
                this.errorCatch(error)
            }
        },
        modalEliminarResidente(){
            this.$refs.abrirEliminarResidente.toggle()
        },
        genero(genero){
            switch (genero){
            case 2:
                return "Masculino"
            case 3:
                return "Femenino"
            default:
                return "Otro"
            }
        },
        tipoResidente(tipo){
            switch (tipo){
            case 1:
                return "en arriendo"
            case 2:
                return "propia"
            
            default:
                break;
            }
        },
        anos(val){
            if(_.isEmpty(val)) return "No registra"
            let anos = moment().diff(val,'years',false)
            return `Edad: ${anos} años`
        },
        detalleResidente(val){
            this.$router.push({name: 'viviendas.ver.residente.personal', params: { id_residente: val.id }})
        }
    }
    
}
</script>
<style lang="scss" scoped>
.card-residente{
    max-width: 350px;
    width: 350px;
    .like-img{
        width: 52px;
        height: 52px;
        i::before{
            margin-right: 0px !important;
            margin-left: 0px !important;
        }
    }
    .bg1{
        background: #DBDBDB;
    }
    .bg2{
        background: #80DCFF;
    }
    .bg3{
        background: #FFD54F;
    }
    .bg-phone{
        background: #FFF1C4;
    }
    .bg-gender{
        background: #AAC3FF;
    }
    .like-img-mini{
        width: 32px;
        height: 32px;
        i::before{
            margin-right: 0px !important;
            margin-left: 0px !important;
        }
    }
    .mas-opciones{
        width: 32px;
        height: 32px;
        background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #CFD7DF;
    }
    
}
</style>