<template>
    <div class="galeria5">
        <el-image ref="refOpenImages" style="width: 100px; height: 100px" :src="soloImg[0]" :preview-src-list="soloImg" class="d-none" />
        <div class="img-padre cr-pointer" :style="`${wPadre};background-image: url( ${imgSeleccionadaSrc});`" @click="openModalImg"/>
        <div class="img-hijos mt-3 d-flex justify-content-start" :style="`${wHijo}`">
            <div v-for="data in listado" :key="data.id" class="img-child mr-2 br-4 cr-pointer" :class="imgSeleccionada == data.id ? 'seleccionado':''" @click="seleccionarImg(data.id, data.img)">
                <img :src="data.img" alt="" class="br-4 w-100 h-100">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GaleriaCinco',
    data(){
        return{
            imgSeleccionada: null,
            imgSeleccionadaSrc: '/img/noImg/anuncio.svg',
            soloImg: [],
        }
    },
    props: {
        listado: {
            type: Array,
            default: () => []
        },
        wPadre: {
            type: String,
            default: 'width:600px; height:430px'
        },
        wHijo: {
            type: String,
            default: 'width:488px'
        }
    },
    watch: {
        listado(val){
            const imagenes = val.map(el=>el.img);
            this.soloImg = imagenes;
        },
    },
    methods:{
        seleccionarImg(id,bg){
            this.imgSeleccionada = id
            this.imgSeleccionadaSrc = bg
        },
        openModalImg(){
            this.$refs.refOpenImages.$el.childNodes[0].click();
        },
    }
}
</script>
<style lang="scss" scoped>
.galeria5{
    .img-padre{
        background-size: 100% 100%;
        border-radius: 4px;
        transition: background-image 0.5s ease-in-out;
    }
    .img-hijos{
        .img-child{
            width: 87px;
            height: 87px;
            &:last-child{
                margin-right: 0px !important;
            }
            &.seleccionado{
                border: 2px solid #109881;
                &>img{
                    border: 2px solid white;
                }
            }
        }
    }
}
</style>