import Chat from '../../services/chats/chat-admin'
// state
export const state = {  
    chatsResidentes: [],
    residenteActivo:{ grupos:[]},
    conversacionResidente:[],
    mensajesTodos:[],
    pagina:0,
    masMensajes:false
}

// getters
export const getters = {
    chatsResidentes: state => state.chatsResidentes,
    residenteActivo: state => state.residenteActivo,
    conversacionResidente: state => state.conversacionResidente,
    pagina: state => state.pagina,
    masMensajes: state => state.masMensajes,
}

// mutations
export const mutations = {
    setChatsResidentes: (state,payload) => {
        state.chatsResidentes = payload;
    },
    setChatsGrupos: (state,payload) => {
        state.chatsGrupos = _.orderBy(payload,['fecha_ultimo_chat'],['desc']);
    },
    setResidenteActivo: (state,payload) => {
        state.residenteActivo = payload;
    },
    setPaginacion:(state,payload)=>{
        if(state.pagina === payload.last_page){
            state.masMensajes = false
        }else{
            state.masMensajes = true
        }
        state.pagina = payload.current_page
    },
    setConversacionResidente: (state,payload) => {

        state.mensajesTodos = [...payload, ...state.mensajesTodos]
        const groupFechaMensajes = _.groupBy(_.orderBy(state.mensajesTodos,'created_at','DESC'), 'fecha')
       
        const mensajes = Object.keys(groupFechaMensajes).map(key => ({ key, mensajes: groupFechaMensajes[key] }));
        state.conversacionResidente = _.orderBy(mensajes,'key','DESC');
    },
    clearConversacion:(state) => {
        state.mensajesTodos = []
        state.conversacionResidente = [],
        state.pagina = 0
        state.masMensajes = false
    },
    setLista:(state, payload) => {
        
        const find = state.chatsResidentes.find(u => u.id_user == payload.id_user)
        if(!find){
            state.chatsResidentes.unshift(payload)
        }
    },
    initTabResidentes:(state) => {
        state.residenteActivo = { grupos:[]}
        state.conversacionResidente = []
        state.mensajesTodos = []
        state.pagina = 0
        state.masMensajes = false
    },
    setListadoChats:(state, payload) => {
        const index = state.chatsResidentes.findIndex(u => u.id_user == payload.id_user)
        
        if(index > -1){
            //Removemos el chat actual
            state.chatsResidentes.splice(index,1)
        }
        //Agregamos del primero el chat entrante
        state.chatsResidentes.unshift(payload)
    }
}

export const actions = {
    async getDataResidente({ commit, state }, payload){

        if(state.residenteActivo.id != payload.id_user){
            commit('clearConversacion')
        }else{
            return
        }

        commit('setLista', payload)
        
        const { data } = await Chat.getDetalleResidente(payload.id_user)
        commit('setResidenteActivo',data.usuario)
  
    },
    async getConversacion({commit,state}, payload){
        state.pagina = state.pagina + 1
        const params = {
            page:state.pagina,
            id_user:payload.id_user
        }

        const {data} = await Chat.getConversacionAdminResidente(params)

        commit('setPaginacion',data.data)
        commit('setConversacionResidente',data.data.data)
    },
    setNewMensaje({commit}, payload){

        commit('setConversacionResidente',[payload.message])

        //console.log([payload.message]);
    },
    updateListado({commit, state}, payload){
        //if(state.residenteActivo.id_user && payload.chat.id_user == state.residenteActivo.id_user) return
        commit('setListadoChats',payload.chat)
    }
    
}
