import axios from 'axios';
import store from '~/store'
import systemRoutes from '~/router/routes';

export default async(to, from, next) => {
    // console.log("🚀 ~ file: ~ async ~ next:", to.name)

    const rutaDestino = to.name;
    if(rutaDestino === 'login'){
        return next(); // Si la ruta va para login, entonces entra sin problema.
    }

    if(systemRoutes.length > 0){
        let pqrRoutes = [];
        let zonasReservasRoutes = [];
        let pagosRoutes = [];
        let llamadosRoutes = [];
        let chatsRoutes = [];
        let visitasRoutes = [];
        let administracionRoutes = [];

        let routes = _.map(systemRoutes, 'name').filter((item) => item != null && item != undefined);
        let children = _.map(systemRoutes, 'children').filter((item) => item != null && item != undefined);
        let redirect = _.map(systemRoutes, 'redirect').filter((item) => item != null && item != undefined);

        if(children.length > 0){
            let allChildren = children.map((e) => {
                let items = e.filter((item) => item.name != null && item.name != undefined).map((e) => {return e.name});
                return items;
            });
            allChildren.length > 0 ? routes = routes.concat(allChildren.flat()) : [];
        }

        if(redirect.length > 0){
            let allRedirect = redirect.map((e) => {
                return e.name;
            });
            allRedirect.length > 0 ? routes = routes.concat(allRedirect) : [];
        }

        _.uniq(routes).map((element) => {
            if(element){
                const split = element.split('.')[0];

                switch(split.toLowerCase()){
                case "pqr":
                    pqrRoutes.push(element);
                    break;
                case "zonas":
                    zonasReservasRoutes.push(element);
                    break;
                case "pago":
                    pagosRoutes.push(element);
                    break;
                case "llamados":
                    llamadosRoutes.push(element);
                    break;
                case "chats":
                    chatsRoutes.push(element);
                    break;
                case "visitantes":
                    visitasRoutes.push(element);
                    break;
                case "pagos":
                    administracionRoutes.push(element);
                    break;
                }
            }
        });

        const blockedModules = JSON.parse(localStorage.getItem('conjunto_modulos'));

        if(pqrRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_pqr) === 0) return next({ name:'sin.permiso' });
        }
        if(zonasReservasRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_reserva) === 0) return next({ name:'sin.permiso' });
        }
        if(pagosRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_pagos) === 0) return next({ name:'sin.permiso' });
        }
        if(llamadosRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_llamados) === 0) return next({ name:'sin.permiso' });
        }
        if(chatsRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_chat) === 0) return next({ name:'sin.permiso' });
        }
        if(visitasRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_visitas) === 0) return next({ name:'sin.permiso' });
        }
        if(administracionRoutes.includes(rutaDestino)){
            if(Number(blockedModules?.modulo_administracion) === 0) return next({ name:'sin.permiso' });
        }
    }

    const rutasPermisoVigilantes = [ // rutas que solo tiene persimo el vigilante
        'zonas.historial',
        'zonas.reservas.ver',
        'llamados.atencion.listado',
        'llamados.ver',
        'llamados.crear',
        'pqr.solicitudes',
        'pqr.resueltas',
        'pqr.ver.solicitud',
        'correspondencia.listado',
        'correspondencia.ver',
        'correspondencia.crear',
        'visitantes.main',
        'visitantes.visitantes',
        'visitantes.historial'
    ];

    const rutasPermisoContador = [ // rutas que solo tiene persimo el contador
        'dashboard.main',
        'pagos.admin',
        'pagos.admin.recibo.pagos',
        'pagos.admin.registro.pagos',
        'pagos.admin.registro.pagos.detail',
        'pagos.admin.registro.pagos.detail.info',
        'zonas.historial',
        'zonas.reservas.ver',
        'zonas.verificar-pago.listado'
    ];

    // Consultamos el usuario e internamente validamos el token
    if(!store.getters['auth/check']){

        if (localStorage.getItem('token')){
            await store.dispatch('auth/fetchUser')
            const tipoUser = store.getters['auth/user'].tipo

            if (tipoUser == 4){ // se comprueba que el usuario logeado sea tipo vigilante
                if (rutasPermisoVigilantes.includes(to.name)){
                    return next()
                }else{
                    return next({name:'sin.permiso'})
                }
            } else if (tipoUser == 6) { // se comprueba que el usuario logeado sea tipo contador
                if (rutasPermisoContador.includes(to.name)){
                    return next()
                }else{
                    return next({name:'sin.permiso'})
                }
            }
            return next();

        }else{
            return next({name:'login'});
        }
    }
    next();
}

