<template>
  <el-select v-model="id_conjunto" placeholder="Seleccionar conjunto" 
    filterable    
    remote
    :remote-method="remoteMethod"
    class="w-100 mb-3">
    <el-option
    v-for="conjunto in conjuntos"
    :key="conjunto.id"
    :label="conjunto.nombre"
    :value="conjunto.id">
  </el-option>
</el-select>
</template>

<script>
import Conjuntos from '~/services/conjuntos'
export default {   
  data(){
    return{
      conjuntos:[],
      params : {
        search:''
      },
      id_conjunto:null
    }
  },
  created(){
    //this.search()
  },
  watch:{
    id_conjunto(val){
      this.$emit('setConjunto',val)
    }
  },
  methods:{    
    remoteMethod(search){
      this.params.search = search
      setTimeout(() => {
        this.loading = false;
        this.search()
      }, 100);
    },
    async search(){      
      try {
        const {data} = await Conjuntos.filtro(this.params)
        this.conjuntos = data.data	
      } catch (e){
        console.log(e)
      }
    }    
  }
}
</script>

<style>

</style>