import Conjuntos from '../../services/conjuntos'
import * as types from '../mutation-types'
// state
export const state = {  
    conjunto: JSON.parse(localStorage.getItem('conjunto')),
}

// getters
export const getters = {
    conjunto: ({conjunto}) => conjunto
}

// mutations
export const mutations = {
    [types.OBTENER_CONJUNTO](state, { conjunto }){
        state.conjunto = conjunto
        localStorage.setItem('conjunto', JSON.stringify(conjunto))
    },
    clearConjunto(state){
        state.conjunto = null
    }
  
}


// actions
export const actions = {  
    async obtener({ commit }, conjunto ){
        const params = {
            id_conjunto : conjunto
        }
        const { data } = await Conjuntos.getConjunto(params)

        commit(types.OBTENER_CONJUNTO, { conjunto: data.data })
    },
    logOut({commit}){
        commit('clearConjunto')
    }

}
