import axios from 'axios'

const API = '/lineas-atencion'

const LineasAtencion = {
    getLineas(){
        return axios.get(`${API}/listar`)
    },
}

export default LineasAtencion
