<template>
    <el-select v-model="id_grupo" placeholder="Seleccionar grupo" size="small"
               filterable    
               remote
               :remote-method="remoteMethod"
               class="w-100 mb-3"
    >
        <el-option
        v-for="grupo in grupos"
        :key="grupo.id"
        :label="grupo.nombre"
        :value="grupo.id"
        />
    </el-select>
</template>

<script>
import Viviendas from '~/services/viviendas'
export default {   
    data(){
        return{
            grupos:[],
            params : {
                search:''
            },
            id_grupo:null
        }
    },
    watch:{
        id_grupo(val){
            this.$emit('setAgrupacion',val)
        }
    },
    created(){
        this.search()
    },
    methods:{    
        remoteMethod(search){
            this.params.search = search
            setTimeout(() => {
                this.loading = false;
                this.search()
            }, 100);
        },
        async search(){
            try {
                const {data} = await Viviendas.agrupaciones(this.params)
                this.grupos = data.data
            } catch (e){
                this.errorCatch(e)
            }
        },
        setIdGrupo(id_grupo){
            this.id_grupo = id_grupo      
        }
    }
}
</script>

<style>

</style>