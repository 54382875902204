
<template>
    <div class="card-solicitud" @click="verSolicitud(cartelera.id)">
        <div class="container-img">
            <img v-if="cartelera.imagen" :src="cartelera.imagen" alt="" class="obj-cover h-100 w-100" />
            <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="obj-cover h-100 w-100" />
        </div>
        <div class="row mx-0 container-info pb-1 align-content-between" :style="`${$route.name=='anuncios.cartelera' ? 'min-height:100px;' : ''} ${cartelera.destacado ? 'border: 2px solid #20debe;' : ''}`">
            <p class="col-12 f-15 my-1 tres-puntos">{{ cartelera.titulo }}</p>
            <p v-show="cartelera.categoria.dinero" class="col-12 f-17 my-1 ">{{ convertMoney(cartelera.dinero) }}</p>
            <div class="col-auto my-1">
                <div class="categoria d-middle br-20 px-2 f-12" :style="`background: ${cartelera.categoria.color}`">
                    <i :class="`${cartelera.categoria.icono} mr-2 op-05`" />
                    <p class="tres-puntos op-05">{{ cartelera.categoria.nombre }}</p>
                </div>
            </div>
            <div v-if="['anuncios.mis.anuncios'].includes($route.name)" class="col-auto my-1">
                <div v-show="cartelera.estado==1" class="categoria d-middle br-20 px-2 f-12 bg-pendiente">
                    <p class="tres-puntos op-05">Pendiente</p>
                </div>
                <div v-show="cartelera.estado==2" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                    <p class="tres-puntos op-05">Activo</p>
                </div>
                <div v-show="cartelera.estado==3" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                    <p class="tres-puntos op-05">Rechazado</p>
                </div>
                <div v-show="cartelera.estado==4" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                    <p class="tres-puntos op-05">Cancelado Admin</p>
                </div>
                <div v-show="cartelera.estado==5" class="categoria d-middle br-20 px-2 f-12 bg-rechazado">
                    <p class="tres-puntos op-05">Cancelado Cliente</p>
                </div>
                <div v-show="cartelera.estado==6" class="categoria d-middle br-20 px-2 f-12 bg-finalizado">
                    <p>Finalizado</p>
                </div>
            </div>
            <div v-if="$route.name!='anuncios.cartelera'" class="col-12 my-1">
                <div class="d-flex">
                    <div class="tipo-user rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                        <i class="icon-account-outline op-05 f-20" />
                    </div>
                    <div class="tres-puntos ml-2">
                        <p class="f-15 f-500">{{ cartelera.usuario.nombre || "No registra" }}</p>
                        <p class="f-12 tres-puntos">{{ cartelera.vivienda || "No registra" }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cartelera: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        verSolicitud(id){
            this.$router.push({ name:'anuncios.solicitudes.ver', params:{id_cartelera:id} })
        },
    }
}
</script>

<style lang="scss" scoped>
.card-solicitud{
        max-width: 304px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        .container-img{
            height: 182px;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .container-info{
            min-height: 150px;
            // height: 141px;
            border-radius: 8px;
            border:1px solid #DBDBDB;
            position: relative;
            top: -8px;
            background: white;
            .categoria{
                min-height: 22px;
            }
            .tipo-user{
                min-width: 32px;
                max-width: 32px;
                max-height: 32px;
                min-height: 32px;
            }
        }
    }
</style>