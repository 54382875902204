export default [
    {
        path: '/anuncios',
        name: 'anuncios.main',
        redirect: {name: 'anuncios.solicitudes'},
        component: () => import('../../pages/anuncios').then(m => m.default || m),
        children: [
            {
                path: '/anuncios/solicitudes',
                name: 'anuncios.solicitudes',
                component: () => import('../../pages/anuncios/solicitudes').then(m => m.default || m),
            },
            {
                path: '/anuncios/mis_anuncios',
                name: 'anuncios.mis.anuncios',
                component: () => import('../../pages/anuncios/misAnuncios').then(m => m.default || m),
            },
            {
                path: '/anuncios/cartelera',
                name: 'anuncios.cartelera',
                component: () => import('../../pages/anuncios/cartelera').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/anuncios/solicitudes/:id_cartelera',
        name: 'anuncios.solicitudes.ver',
        component: () => import('../../pages/anuncios/verSolicitud').then(m => m.default || m),
    },
    {
        path: '/anuncios/solicitudes/historial/:id_vivienda',
        name: 'anuncios.ver.anuncios.vivienda',
        component: () => import('../../pages/anuncios/verAnuncioVivienda').then(m => m.default || m),
    },
    {
        path: '/anuncios/guardar/:id_cartelera?',
        name: 'anuncios.crear',
        component: () => import('../../pages/anuncios/crearAnuncio').then(m => m.default || m),
    },
    {
        path: '/anuncios/editar/:id_cartelera',
        name: 'anuncios.editar',
        component: () => import('../../pages/anuncios/editarAnuncio').then(m => m.default || m),
    },
    {
        path: '/anuncios/mis_anuncios/:id_cartelera',
        name: 'anuncios.mis.anuncios.ver',
        component: () => import('../../pages/anuncios/verMiAnuncio').then(m => m.default || m),
    },
    {
        path: '/anuncios/cartelera/:id_cartelera',
        name: 'anuncios.cartelera.ver',
        component: () => import('../../pages/anuncios/verCartelera').then(m => m.default || m),
    },
]
