import axios from 'axios'

const API = '/conjuntos'

const Conjuntos = {
    listarConjuntos(){
        return axios(`${API}`)
    },
    filtro(params){
        return axios.get(`${API}/buscar`,{params})
    },
    guardar(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    getConjunto(params){
        return axios.get(`${API}/mostrar/${params.id_conjunto}`)
    },
    getModulos(params){
        return axios.get(`conjuntos/modulos/listar`, {params})
    }
}

export default Conjuntos
