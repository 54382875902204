import Vue from 'vue'
import lodash from 'lodash'
import './vee-validate'
import './draggable'
import './slim'
import './vueperslides'
import './fullcalendar'
import './lottie-animations';
import Formateadores from '~/mixins/Formateadores'
import Global from '~/mixins/general'
// import moment from 'moment'
// moment.updateLocale('es', {
//     week: { dow: 0 }
// })
import moment from 'moment'
moment.updateLocale('es', {
    week: { dow: 0 }
})
Vue.prototype._ = lodash



Vue.mixin(Global)
Vue.mixin(Formateadores)