export default [
    {
        path: '/zonas',
        name: 'zonas.main',
        redirect: {name: 'zonas.reservas.listado'},
        component: () => import('../../pages/zonasComunes').then(m => m.default || m),
        children: [
            {
                path: 'reservas',
                name: 'zonas.reservas',
                redirect: {name: 'zonas.reservas.listado'},
                component: () => import('../../pages/zonasComunes/reservas').then(m => m.default || m),
                children: [
                    {
                        path: 'listado',
                        name: 'zonas.reservas.listado',
                        component: () => import('../../pages/zonasComunes/reservas/vistaListado').then(m => m.default || m),

                    },
                    {
                        path: 'calendario',
                        name: 'zonas.reservas.calendario',
                        component: () => import('../../pages/zonasComunes/reservas/vistaCalendario').then(m => m.default || m),
                    }
                ]
            },
			{
                path: 'pago-pendiente',
                name: 'zonas.pago-pendiente',
                redirect: {name: 'zonas.pago-pendiente.listado'},
                component: () => import('../../pages/zonasComunes/pendientePago').then(m => m.default || m),
                children: [
                    {
                        path: 'listado',
                        name: 'zonas.pago-pendiente.listado',
                        component: () => import('../../pages/zonasComunes/pendientePago/vistaListado').then(m => m.default || m),

                    },
                    {
                        path: 'calendario',
                        name: 'zonas.pago-pendiente.calendario',
                        component: () => import('../../pages/zonasComunes/pendientePago/vistaCalendario').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'verificar-pago',
                name: 'zonas.verificar-pago',
                component: () => import('../../pages/zonasComunes/verificarPago/index').then(m => m.default || m),
                redirect: {name: 'zonas.verificar-pago.listado'},
                children: [
                    {
                        path: 'listado',
                        name: 'zonas.verificar-pago.listado',
                        component: () => import('../../pages/zonasComunes/verificarPago/vistaListado').then(m => m.default || m),

                    },
                    {
                        path: 'calendario',
                        name: 'zonas.vericar-pago.calendario',
                        component: () => import('../../pages/zonasComunes/verificarPago/vistaCalendario').then(m => m.default || m),
                    }
                ]
            },
            {
                path: 'historial',
                name: 'zonas.historial',
                component: () => import('../../pages/zonasComunes/historial/listado').then(m => m.default || m),
            },
            {
                path: 'listado',
                name: 'zonas.listado',
                component: () => import('../../pages/zonasComunes/listadoZonasComunes').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/zonas/reservas/:id_reserva',
        name: 'zonas.reservas.ver',
        component: () => import('../../pages/zonasComunes/reservas/ver').then(m => m.default || m),
    },
    {
        path: '/zonas/crear_zona',
        name: 'zonas.crear.nueva',
        component: () => import('../../pages/zonasComunes/crearZona').then(m => m.default || m),
    },
    {
        path: '/zonas/guardar_zona/:id_zona',
        name: 'zonas.guardar.zona',
        component: () => import('../../pages/zonasComunes/guardarZona').then(m => m.default || m),
    },
]
