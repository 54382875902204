import moment from "moment"
export default {
    methods: {
        
        redondearNumero(valor, decimales = 0){
            valor = valor ?? 0
            return Number(Math.round(valor + 'e' + decimales) + 'e-' + decimales)
        },

        convertMoney(value){
            if(!value) return '$ 0'
            var formatter = new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
            });
            return formatter.format(value);

        },
        formatearFecha(fecha, formatoDestino = 'D MMM Y', formatoOriginal = moment.ISO_8601){
          return this.fechaEsValida(fecha)
              ? moment(fecha, formatoOriginal).format(formatoDestino)
              : (_.isNull(fecha) || _.isUndefined(fecha))
                  ? '-'
                  : 'Fecha Inválida'
        },
        fechaEsValida: (fecha) => moment(fecha,
          [moment.ISO_8601, 'D-M-Y', 'D/M/Y', 'Y-M-D', 'Y/M/D', 'DD MMM Y', 'D MMM Y', 'Y-M-D HH:mm:ss', 'D/M/Y HH:mmA'],
          true)
          .isValid(),
          
        formatearTextoHtml: texto => _.isEmpty(texto) || _.isNull(texto) ? '' : texto.replace(/\n/gi, '<br/>'),
        formatearHora(hora){
            return moment(hora,'HH:mm:ss').format('h:mm a')
        },
    }
}
