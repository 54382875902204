export default [
	{
        path: '/visitantes',
        name: 'visitantes.main',
        redirect: { name: 'visitantes.visitantes' },
        component: () => import('../../pages/visitantes').then(m => m.default || m),
        children: [
            {
                path: 'visitantes',
                name: 'visitantes.visitantes',
                component: () => import('../../pages/visitantes/visitantes').then(m => m.default || m),
            },
            {
                path: 'historial',
                name: 'visitantes.historial',
                component: () => import('../../pages/visitantes/historial').then(m => m.default || m),
            }
		]
	}
]