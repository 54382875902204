import axios from 'axios'

const ApiAdmin = 'chats/admin'
const Api = 'chats'

const Chats = {
    getChatsResidentes(){
        return axios(`${ApiAdmin}?residentes=1`)
    },
    getChatsGrupos(){
        return axios(`${ApiAdmin}?grupos=1`)
    },
    getDetalleResidente(idUser){
        return axios(`${ApiAdmin}/detalle/residente/${idUser}`)
    },
    buscarResidentes(params){
        return axios(`${ApiAdmin}/grupo/buscar-residentes`,{params})
    },
    agregarResidentesGrupo(form){
        return axios.post(`${ApiAdmin}/grupo/agregar-usuarios`,form)
    },
    getUsuariosGrupo(idGrupo){
        return axios(`${ApiAdmin}/grupo/${idGrupo}/residentes`)
    },
    buscarChatResidente(params){
        return axios(`${ApiAdmin}/residentes/busqueda`,{params})
    },
    setAdmin(form){
        return axios.put(`${ApiAdmin}/grupo/set-admin`,form)
    },
    setEscrituraGrupo(idGrupo,form){
        return axios.put(`${ApiAdmin}/grupo/${idGrupo}/escritura`,form)
    },
    setEstadoGrupo(idGrupo,form){
        return axios.put(`${ApiAdmin}/grupo/${idGrupo}/estado`,form)
    },
    eliminarResidentesGrupo(idGrupo,params){
        return axios.delete(`${ApiAdmin}/grupo/${idGrupo}/residentes`,{params})
    },
    deleteGrupo(idGrupo){
        return axios.delete(`${ApiAdmin}/grupo/${idGrupo}`)
    },
    crearGrupo(form){
        return axios.post(`${ApiAdmin}/grupo`,form)
    },
    editarGrupo(idGrupo,form){
        return axios.post(`${ApiAdmin}/grupo/${idGrupo}`,form)
    },


    enviarMensajeGrupo(form){
        return axios.post(`${Api}/grupo/mensaje`,form)
    },
    getConversacionAdminResidente(params){
        return axios(`${Api}/mensajes`,{params})
    },
    enviarMensajeResidente(form){
        return axios.post(`${Api}/residente/mensaje`,form)
    },
    getDetalleGrupo(idGrupo){
        return axios(`${Api}/detalle/grupo/${idGrupo}`)
    },
    getConversacionAdminGrupo(params){
        return axios.get(`${Api}/mensajes-grupo`,{params})
    },



    
   
    
}

export default Chats
