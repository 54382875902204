<template>
  <el-select v-model="id_vivienda" placeholder="Seleccionar vivienda" size="small"
    filterable    
    remote
    :remote-method="remoteMethod"
    class="w-100 mb-3">
    <el-option
    v-for="vivienda in viviendas"
    :key="vivienda.id"
    :label="vivienda.nombre"
    :value="vivienda.id">
  </el-option>
</el-select>
</template>

<script>
import Viviendas from '~/services/viviendas'
export default {   
  data(){
    return{
      viviendas:[],
      params : {
        search:'',
        id_padre:null
      },
      id_vivienda:null
    }
  },
  created(){
    //this.search()
  },
  watch:{
    id_vivienda(val){
      this.$emit('setVivienda',val)
    }
  },
  methods:{    
    remoteMethod(search){
      this.params.search = search
      setTimeout(() => {
        this.loading = false;
        this.search()
      }, 100);
    },
    async search(){      
      try {
        const {data} = await Viviendas.hijas(this.params)
        this.viviendas = data.data
      } catch (e){
        this.errorCatch(e)
      }
    },
    setIdPadre(id){
      this.params.id_padre = id
      this.id_vivienda = null
      this.search()
    },
    setIdVivienda(id_vivienda){
      console.log(id_vivienda)
      this.id_vivienda = id_vivienda
    }
  }
}
</script>

<style>

</style>