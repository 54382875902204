<template>
    <div class="chat-right py-2">
        <div id="scrollGeneral" class="overflow-auto scroll-none" style="height: calc(100vh - 130px);">
            <div class="row mx-0">
                <div v-for="(c,key) in conversaciones" :key="key" class="w-100">
                    <div class="col-12 text-center my-3">
                        <span class="text-86 bg-f5 border f-12 rounded-pill px-2 py-1">{{ c.key | helper-fecha('DD MMMM [de] YYYY') }}</span>
                    </div>
                    <div v-for="(m,i) in c.mensajes" :key="i">
                        <div v-if="m.propio" class="col-9 ml-auto mb-2">
                            <div class="row mx-0 justify-content-end">
                                <div class="col-auto px-0 br-5 chat-bubble chat-bubble-right">
                                    <div class="row mx-0 my-1 f-13">
                                        <div class="col py-1">
                                            <div class="row mx-0 justify-content-end">
                                                <p v-if="m.tipo === 1" style="word-break: break-all;white-space: break-spaces;">
                                                    {{ m.mensaje }}
                                                </p>
                                                <img v-if="m.tipo === 2" class="w-100 cr-pointer" :src="m.mensaje" style="max-width:200px;max-height:200px;" @click="verImagen(m.mensaje)" />
                                            </div>
                                            <div class="row mx-0 justify-content-end f-11 text-86">
                                                {{ m.created_at | helper-fecha('HH:mm a' ) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-bubble-arrow-right" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-9 mr-auto mb-2">
                            <div class="row mx-0">
                                <div class="col-auto px-0 br-5 chat-bubble chat-bubble-left">
                                    <div class="row mx-0 my-1 f-13">
                                        <div class="col py-1">
                                            <div class="row mx-0 justify-content-end">
                                                <p v-if="m.tipo === 1" style="word-break: break-all;white-space: break-spaces;">
                                                    {{ m.mensaje }}
                                                </p>
                                                <img v-if="m.tipo === 2" class="w-100 cr-pointer" :src="m.mensaje" style="max-width:200px;max-height:200px;" />
                                            </div>
                                            <div class="row mx-0 justify-content-end f-11 text-86">
                                                {{ m.created_at | helper-fecha('HH:mm a') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-bubble-arrow-left" />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="chat-input">
            <div class="row mx-0">
                <div class="col-12">
                    <div class="d-middle">
                        <div class="position-relative w-90">
                            <el-input v-model="inputChat" placeholder="Mensaje" type="textarea" class="chat-input-inner-bg" @keyup.enter.native="enviarMensajeResidente" />
                            <div class="">
                                <el-upload
                                ref="upload"
                                class="upload-chat"
                                action="#"
                                :auto-upload="false"
                                :show-file-list="false"
                                :on-change="subirImagen"
                                :limit="1"
                                accept="image/*"
                                >
                                    <i v-show="inputChat ===''" class="icon-add-img" />
                                </el-upload>
                            </div>
                        </div>
                        <i class="icon-navigation ml-2 f-35 text-general cr-pointer" :class="inputChat ? 'activo cr-pointer' : ''" @click="enviarMensajeResidente" />
                    </div>
                </div>
            </div>
        </div>
        <modalImagen ref="modalVerImagen" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Chats from '~/services/chats/chat-admin'
export default {
    data(){
        return{
            inputChat:'',
            conversaciones: [],
            message: [],
        }
    },
    computed:{
        idResidente(){
            return this.$route.params.id_residente
        },
        ...mapGetters({
            conjunto:'conjunto/conjunto'
        })
    },
    mounted(){
        this.getMensajeResidente()
        setTimeout(() => {
            this.scrollToBottom();    
        }, 500);
    },
    methods:{
        async getMensajeResidente(){
            try {
                const params = {
                    page: 1,
                    id_user: this.idResidente
                }
                
                const {data} = await Chats.getConversacionAdminResidente(params)
                this.message = data.data.data   
                
                const groupFechaMensajes = _.groupBy(_.orderBy(this.message,'created_at','DESC'), 'fecha')       
                const mensajes = Object.keys(groupFechaMensajes).map(key => ({ key, mensajes: groupFechaMensajes[key] }));
                this.conversaciones = _.orderBy(mensajes,'key','DESC');

                this.sockets.subscribe(`conjunto-${this.conjunto.id}-chat-residente-${this.idResidente}`, (data) => {
                    console.log(data,'suscrito al chat');
                    this.listarMensages(JSON.parse(data))
                });
            } catch (error){
                this.errorCatch(error)
            }
        },
        listarMensages(message){
            this.message.push(message.message)

            const groupFechaMensajes = _.groupBy(_.orderBy(this.message,'created_at','DESC'), 'fecha')       
            const mensajes = Object.keys(groupFechaMensajes).map(key => ({ key, mensajes: groupFechaMensajes[key] }));
            this.conversaciones = _.orderBy(mensajes,'key','DESC');
        },        
        async enviarMensajeResidente(){
            try {
                const form = {
                    mensaje: this.inputChat,
                    id_user_receptor: this.idResidente
                }
                
                const {data} = await Chats.enviarMensajeResidente(form)
                this.notificacion('Éxito','Mensaje enviado correctamente','success')
                this.inputChat = ''
            } catch (error){
                this.errorCatch(error)
            }
        },
        async subirImagen(file, filelist){
            try {
                const tipo = this.fileType(file.name)
                if(tipo !== 'image/*'){
                    this.$refs.upload.clearFiles()
                    this.notificacion('Advertencia','Por favor seleccione solo imagenes','warning')
                    return
                }

                const model = {
                    mensaje: file.raw,
                    id_user_receptor: this.idResidente
                }
                const form = this.crearFormData(model)
                
                const {data} = await Chats.enviarMensajeResidente(form)
                this.notificacion('Éxito','Imagen enviada correctamente','success')
                this.$refs.upload.clearFiles()
            } catch (error){
                this.errorCatch(error)
            }
        },
        verImagen(img){
            this.$refs.modalVerImagen.toggle(img)
        },
        scrollToBottom(){
            /* let chat = document.getElementById('scrollGeneral'); */
            
            /* let objDiv = document.getElementById("scrollGeneral");
            console.log(objDiv.scrollTop , objDiv.scrollHeight);
            objDiv.scrollTop = objDiv.scrollHeight; */

            $("#scrollGeneral").scrollTop($("#scrollGeneral")[0].scrollHeight);
        }
    }
}
</script>
<style lang="scss" scoped>
$bg-chat: #fff;
$bg-bubble-left: #f5f5f5;
$bg-bubble-right: #C8F5E6;
$font-size-bubble: 13px;
$color-text-bubble: #000;
.chat-right{
        background: $bg-chat 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000014;
        border: 1px solid #dbdbdb;
        height: 100%;
        position: relative;
        .chat-bubble{
            border: 1px solid #F8FCFF;
            font-size: $font-size-bubble;
            color: $color-text-bubble;
            &-left{
                background: $bg-bubble-left;
            }
            &-right{
                background: $bg-bubble-right;
            }
            &-arrow-left{
                @extend .arrows-chat;
                left: -10px;
                border-color: $bg-bubble-left #00000000  #00000000 transparent;
            }
            &-arrow-right{
                @extend .arrows-chat;
                right: -10px;
                border-color: $bg-bubble-right #00000000  #00000000 transparent;
            }
            .arrows-chat{
                border-style: solid;
                border-width: 10px;
                top: 0px;
                height: 0;
                position: absolute;
                width: 0;
            }
        }
        .chat-input{
            .icon-add-img{
                font-size: 20px;
                opacity: 0.5;
                cursor: pointer;
            }
            .activo{
                color:#109881;
            }
            .icon-navigation{
                transform: rotate( 90deg );
            }
        }
    }
</style>